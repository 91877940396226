<template>
  <div>
    <div style="margin: 20px 0 0px 20px">
      <MyBreadcrumb></MyBreadcrumb>
    </div>
    <div class="star-statistics">
      <div class="search">
        <div class="search-item">
          <div class="name">
            一级分类:
          </div>
          <div class="cont">
            <div class="values">
              <div :class="curCategoryId == item.id ? 'active' : ''" v-for="item in categoryList" :key="item.id + 'category'" @click="changeSearch(item,'curCategoryId')">{{item.name}}</div>
            </div>
          </div>
        </div>
        <div class="search-item" v-if="secondCategoryList.length">
          <div class="name">
            二级分类:
          </div>
          <div class="cont">
            <div class="values" >
              <div :class="cursecondCategoryId == item.id ? 'active' : ''" v-for="item in secondCategoryList" :key="item.id + 'secondCategory'" @click="changeSearch(item,'cursecondCategoryId')">{{item.name}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="star-statistics-cont">
        <div class="tit">星级
          <Tooltip>
            <Icon type="ios-help-circle-outline" :size="18" />
            <div slot="content">
              <p>(题数 * 星数)的和 / 总题数 = 平均星级</p>
            </div>
          </Tooltip>
        </div>
        <div id="star" style="width: 100%;height:500px"></div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "starStatistics.vue",
  data(){
    return{
      curCategoryId:'',
      cursecondCategoryId:'',
      categoryList:[],
      secondCategoryList:[],
      categoryStatList:[],
    }
  },
  created(){
    this.getCategoryList();
  },
  methods:{
    getCategoryList(){
      let data = {
        page:1,
        pageSize:10,
      };

      this.api.dataset.categoryForm(data).then((res)=>{

        //一级标签
        this.categoryList = [
          ...res.categoryFirstList
        ];
        this.curCategoryId = this.categoryList[0].id;
        this.getSecondCategoryList();
      })
    },
    getSecondCategoryList(){
      let data = {
        page:1,
        pageSize:10,
        category_id:this.curCategoryId
      };

      this.api.dataset.categoryForm(data).then((res)=>{

        //二级标签
        this.secondCategoryList = [
          ...res.categorySecondList
        ];
        console.log(this.secondCategoryList,'this.secondCategoryList')

        this.cursecondCategoryId = this.secondCategoryList[0].id;
        this.getData();

      })
    },
    changeSearch(data,name){
      this[name] = data.id;
      if(name == 'curCategoryId'){
        this.getSecondCategoryList();
      }
      if(name == 'cursecondCategoryId'){
        this.getData();
      }
    },
    getData(){
      let params = {
        category_id:this.cursecondCategoryId
      }
      this.api.dataset.datasetCategoryProfiles(params).then((res)=>{
        let seriesData = [];
        let legendData = [];
        this.categoryStatList = res.categoryStatList;
        let date = res.categoryStatList[0].map((item)=>{
          return item.date;
        });
        res.categoryStatList.forEach((item)=>{
          let data = [];
          item.forEach((sItem)=>{
            data.push(sItem.star_average)
          })
          seriesData.push({
            name: item[0].category.name,
            type: 'line',
            // stack: 'Total',
            data:data,
          });
          legendData.push(item[0].category.name)
        })
        this.setChart(date,seriesData,legendData);
      })
    },
    setChart(date,seriesData,legendData){
      var chartDom = document.getElementById('star');
      var myChart = this.$echarts.init(chartDom);
      var option;

      option = {
        tooltip: {
          trigger: 'axis',
          formatter: (params, ticket, callback)=> {
            var htmlStr = '';
            for(var i=0;i<params.length;i++){
              var param = params[i];
              var xName = param.name;//x轴的名称
              var seriesName = param.seriesName;//图例名称
              var value = param.value;//y轴值
              var color = param.color;//图例颜色

              if(i===0){
                htmlStr += xName + '<br/>';//x轴的名称
              }
              htmlStr +='<div>';

              // 具体显示的数据【字段名称：seriesName，值：value】
              // 这里判断一下name，如果是我们需要特殊处理的，就处理
              htmlStr += '<span style="margin-right:5px;display:inline-block;width:10px;height:10px;border-radius:5px;background-color:'+color+';"></span>';
              htmlStr += seriesName + '：' + value + '/' + this.categoryStatList[param.seriesIndex][param.dataIndex].total_data_count;

              htmlStr += '</div>';
            }
            return htmlStr;
          }
        },
        legend: {
          data: legendData
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: date
        },
        yAxis: {
          type: 'value'
        },
        series: seriesData
      };

      option && myChart.setOption(option,true);

    }
  }
}
</script>

<style scoped lang="scss">
.star-statistics{
  margin: 20px;
  padding:20px;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
  border-radius: 4px;
  .search{
    margin-top: 20px;
    margin-bottom: 20px;
    .search-item{
      margin-bottom: 15px;
      display: flex;
      justify-content: flex-start;
      font-size: 14px;

      .name{
        padding: 5px 0;
        width: 75px;
        text-align: right;
      }
      .cont{
        margin-left: 15px;
        flex: 1;
        display: flex;
        justify-content: space-between;

        .values{
          display: flex;
          justify-content: flex-start;
          flex-flow: wrap;
          transition: max-height 0.3s linear;
          overflow: hidden;

          >div{
            padding: 5px 10px;
            //margin: 0 10px 10px 5px;
            cursor: pointer;
          }
          >div.active{
            background-color: #2d8cf0;
            color:#FFFFFF;
            border-radius: 4px;
          }
        }
        .more{
          padding: 5px 0;
          width: 80px;
          text-align: right;
          cursor: pointer;
          .more-icon{
            transition: transform 0.3s linear;
          }
        }
      }
    }
  }
  .star-statistics-cont{
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #dcdee2;
    .tit{
      font-size: 14px;
    }
  }
}
</style>
